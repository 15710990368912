// Here you can add other styles
.card-header:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_drop {
  flex: unset !important;
  width: 12% !important;
  margin-right: 1rem !important;
}

.c-spinning {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

html:not([dir="rtl"]) .form-switch {
  display: flex;
}

html:not([dir="rtl"]) .ms-3 {
  margin-left: 1rem !important;
  margin-right: 0.3rem;
}

.header-nav .nav-link {
  font-size: 21px;
}

.pagination {
  cursor: pointer;
  float: right;
}

.logo-img {
  width: 73%;
  cursor: pointer;
}

.anchor-tag {
  text-decoration: none;
}

.dash_box {
  height: 150px;
}

.clickable {
  cursor: pointer;
  color: #3498fb !important;
}

.form_select {
  flex: unset !important;
  width: 11% !important;
  min-width: unset !important;
}

.mb-3 {
  justify-content: flex-end;
  margin-top: unset;
  margin-bottom: unset !important;
}

.input-filter {
  flex: unset !important;
  width: 24% !important;
  min-width: unset !important;
}

.filter_button {
  float: right;
  margin: 0 1rem 0 1rem;
  width: 8rem;
  margin-left: 11px !important;
  border-radius: 0.25rem !important;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  color: #24242491;
}

.pass-input {
  margin-top: 1.5rem;
}

.pass {
  width: 11rem;
}

.pass_input {
  position: relative;
}

.eyeIcon {
  position: absolute;
  right: 6%;
  top: 20%;
}

.eyeNewIcon {
  position: absolute;
  right: 6%;
  top: 51%;
}

.eyeConIcon {
  position: absolute;
  right: 6%;
  top: 83%;
}

.sidebar-nav .nav-link {
  padding: 1.1445rem 1rem !important;
}

.text-medium-emphasis {
  margin-top: 0.5rem;
}

.date-in {
  width: 18% !important;
}

.status-in {
  width: 15% !important;
}

.react-datepicker-wrapper {
  width: 13.5% !important;
}

.filter-input-date {
  height: 38px;
  width: 100%;
}

.group-date {
  margin-right: 1rem;
}

.card-group {
  flex-flow: row-reverse !important;
}

.park-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;

  img {
    width: 70%;
  }
}

.border-none {
  border: unset;
}

// .flex-content {
//   // display: flex;
//   // justify-content: space-between;
//   // align-items: baseline;
// }
.card-div {
  padding: 2rem 2.5rem;
}

.content-divider {
  margin-top: 4rem;
  margin-bottom: 1.2rem;
}

.content-divider::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: black;
}

.top-content {
  margin-bottom: 1.2rem;
}

.form-input-con {
  width: 70% !important;
  border: none;
  border-bottom: 1px solid #f6f6f6;

  border-radius: 0;
  color: #212121;
  padding: 0.375rem 0 !important;
}

.form-input-con:focus {
  box-shadow: none;
  border-color: #2d2942;
}

.col-class {
  color: white;
}

.col-class:hover {
  color: white;
}

.modal_anim {
  transition: transform 2s;


}

.modal-content {
  margin-left: 136%;
  margin-top: 35%;
}

.header.header-sticky {
  height: 83px;
}

.cursor-point {
  font-size: 16px;
  color: #3498fb !important;
  cursor: pointer;
}

.image-up {
  padding: 10px;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fa-upload {
  margin-right: 0.7rem;
}

.image-inherit {
  display: none;
}

.c-spinning-img {
  border: 1px solid black;
  margin-top: 1rem;
  padding: 10px;
}

.fa-times {
  float: right;
  cursor: pointer;
}

.sidebar-toggler {
  background: #3c4b64 !important;
}

.sidebar-brand {
  background: #3c4b64 !important;
}

.map-gal {
  width: 75%;
  height: 100%;
  cursor: pointer;
  border-radius: 8px;
}

.fa-circle {
  font-size: 10px;
}

.bullet-wrap {
  display: flex;
  width: 33%;

  align-items: baseline;
  margin-top: 2rem;
}

.bullet-wrap h6 {
  margin-left: 1rem;
  font-weight: 400;
}

.img-span:not(:first-child) {
  margin-left: 2rem;
}

.amenity-wrap {
  display: flex;
  flex-wrap: wrap;
}

.react_lightgallery_item {
  width: 15%;
  margin-left: 1rem;
}

.imge-div-wrapper {
  display: contents;
}

.border-trans {
  border: none;
  background: transparent;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
}

.border-trans:focus-visible {
  outline: none;
}

.mt-card {
  margin-top: 1.5rem;
}

.mt-card .card .card-header {
  padding: 17px;
}

html:not([dir="rtl"]) .sidebar-nav .nav-icon:first-child {
  margin-left: -1rem;
  margin-right: 0.2rem;
}

.sidebar-nav .nav-link {
  padding: 1.1445rem 2.3rem !important;
}

.map-modal {
  // margin-top: 1.6rem;
  width: 49%;
}

.card-flexing {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.padding-map {
  padding: 16px;
}

.form-control[readonly] {
  background: transparent;
}

.container-lg {
  max-width: 100% !important;
}

.mt-6 {
  margin-top: 5rem !important;
}

.pad-disappear {
  padding: 0rem 2.5rem !important;
}

.no-data-found {
  font-weight: 600;
  font-size: 20px;
  padding: 14px;
  margin-bottom: 0;
  color: #24242491;
}

.rules_wrap {
  width: 55% !important;
}

.block_modal {
  background-color: #e55353;
  color: #fff;
}

.block-reason .modal-dialog .modal-content {
  border: 1px solid #e55353;
}

.block-text {
  min-height: calc(5.5em + 0.75rem + 2px) !important;
}

.select-gender {
  width: 71% !important;
}

.bread-manage {
  color: var(--cui-link-color, #321fdb);
  text-decoration: underline;
  cursor: pointer;
}

.non_editable {
  color: #757575 !important;
  cursor: not-allowed;
}

.paginate-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paginate-flex-box {
  display: flex;
  width: 6%;
  margin-bottom: 0.8rem;
}

.paginate-box {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #321fdb;
  border-radius: 7px;
  padding: 4px;
  cursor: pointer;
}

.paginate-disable {
  border: 1px solid #00000063;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  padding: 4px;
  pointer-events: none;
}

.ma-r {
  margin-right: 1rem;
}

.fa-angle-left {
  // margin-right: 1rem;
  font-size: 23px;
  cursor: pointer;
  color: #321fdb;
}

.fa-angle-right {
  font-size: 23px;
  cursor: pointer;
  color: #321fdb;
}

.disabled {
  color: #00000063;
  pointer-events: none;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.yellow {
  color: #a7a719c7 !important;
}

// html:not([dir="rtl"]) .sidebar.hide:not(.sidebar-end) {
//   margin-left: unset !important;
// }
@media (min-width: 1220px) and (max-width: 1400px) {
  .filter_button {
    width: 5rem;
  }
}


.buttons_flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;

}

.nobor-rad:focus {
  background-color: none !important;
  color:none !important;
  outline: none !important;
  box-shadow: none !important;
}

.nmt-3 {
  margin-top: 3rem !important;
}

.nmt-30 {
  margin-top: 100% !important;
}

.cborderBottom {
  border-radius: none !important;
  border: none;
  border-bottom: 1px solid #a7a6a6 !important;
  outLine: none !important;
  padding-left: 0;

}

.newa {
  font-weight: 300 !important;
  color: #808080 !important;
  background-color: #ececec;
}

.focus-change:focus {

  outline: none !important;

}

.noback{
  background-color: inherit !important;
}
.css-1ptx2yq-MuiInputBase-root-MuiInput-root::after {
  border-bottom: 1px solid grey !important;
}


#standard-basic-label::selection{
  background: grey;
}

.MuiFormControl-root{
  width: 100% !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color: grey !important
}

#standard-basic{
  font-weight: 350 !important;
}