// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "../../node_modules/@coreui/coreui/scss/coreui.scss";

// Import Chart.js custom tooltips styles
// @import "../../node_modules/@coreui/chartjs/scss/coreui-chartjs.scss";
// @import "@coreui/chartjs/scss/coreui-chartjs";
// @import "../../node_modules/@coreui/chartjs/scss/coreui-chartjs.scss";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "./custom";
@import '~antd/dist/antd.css';
